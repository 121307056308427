import BlogList from "components/sections/blog-list"

export default function RecentBlogsSection({ blogs, title }) {
    return (
        <section className="py-10">
            <div className="mx-auto max-w-7xl">
                <div className="flex justify-center items-center">
                    <h2 className="text-pink-500 text-3xl font-light">{title}</h2>
                </div>
                <BlogList blogs={blogs || []} pageSize={3} />
            </div>
        </section>
    );
}
